var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"编辑BOM","width":1000,"confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"父物料"}},[_c('GoodsSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'goods',
                {
                  initialValue: _vm.item.goods,
                  rules: [{ required: true, message: '请选择物料' }],
                },
              ]),expression:"[\n                'goods',\n                {\n                  initialValue: item.goods,\n                  rules: [{ required: true, message: '请选择物料' }],\n                },\n              ]"}],attrs:{"defaultItem":_vm.item.goods_item},on:{"select":(item) => (_vm.productItem = item)}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"父物料编号"}},[_c('a-input',{attrs:{"value":_vm.productItem.number,"disabled":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"父物料规格"}},[_c('a-input',{attrs:{"value":_vm.productItem.spec,"disabled":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"父物料尺寸"}},[_c('a-input',{attrs:{"value":_vm.productItem.drawing_number,"disabled":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"基础BOM"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'is_basic',
                {
                  valuePropName: 'checked',
                  initialValue: _vm.item.is_basic,
                },
              ]),expression:"[\n                'is_basic',\n                {\n                  valuePropName: 'checked',\n                  initialValue: item.is_basic,\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', { initialValue: _vm.item.remark, rules: [{ max: 256, message: '超出最大长度(256)' }] }]),expression:"['remark', { initialValue: item.remark, rules: [{ max: 256, message: '超出最大长度(256)' }] }]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"BOM清单","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('BOMComponentTable',{directives:[{name:"decorator",rawName:"v-decorator",value:(['bom_component_items', { initialValue: _vm.item.bom_component_items }]),expression:"['bom_component_items', { initialValue: item.bom_component_items }]"}]})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"附件","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('BOMFileUpload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['bom_files', { initialValue: _vm.item.bom_files }]),expression:"['bom_files', { initialValue: item.bom_files }]"}],attrs:{"initialItems":_vm.item.bom_file_items}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }